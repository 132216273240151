/* ========================================================================
 * DOM-based Routing
 * Based on http://goo.gl/EUTi53 by Paul Irish
 *
 * Only fires on body classes that match. If a body class contains a dash,
 * replace the dash with an underscore when adding it to the object below.
 *
 * .noConflict()
 * The routing is enclosed within an anonymous function so that you can
 * always reference jQuery with $, even when in .noConflict() mode.
 * ======================================================================== */

(function($) {
  // Use this variable to set up the common and page specific functions. If you
  // rename this variable, you will also need to rename the namespace below.
  var Sage = {
    // All pages
    'common': {
      init: function() {
		  (function($) {
		  /*
		  *  add_marker
		  *
		  *  This function will add a marker to the selected Google Map
		  *
		  *  @type	function
		  *  @date	8/11/2013
		  *  @since	4.3.0
		  *
		  *  @param	$marker (jQuery element)
		  *  @param	map (Google Map object)
		  *  @return	n/a
		  */

		  function add_marker( $marker, map ) {

		  	// var
		  	var latlng = new google.maps.LatLng( $marker.attr('data-lat'), $marker.attr('data-lng') );

		  	// create marker
		  	var marker = new google.maps.Marker({
		  		position	: latlng,
		  		map			: map,
				icon: template_url + "/dist/images/mappoint.png",
		  	});

		  	// add to array
		  	map.markers.push( marker );

		  	// if marker contains HTML, add it to an infoWindow
		  	if( $marker.html() )
		  	{
		  		// create info window
		  		var infowindow = new google.maps.InfoWindow({
		  			content		: $marker.html()
		  		});

		  		// show info window when marker is clicked
		  		google.maps.event.addListener(marker, 'click', function() {

		  			infowindow.open( map, marker );

		  		});
		  	}

		  }

		  /*
		  *  center_map
		  *
		  *  This function will center the map, showing all markers attached to this map
		  *
		  *  @type	function
		  *  @date	8/11/2013
		  *  @since	4.3.0
		  *
		  *  @param	map (Google Map object)
		  *  @return	n/a
		  */

		  function center_map( map ) {

		  	// vars
		  	var bounds = new google.maps.LatLngBounds();

		  	// loop through all markers and create bounds
		  	$.each( map.markers, function( i, marker ){

		  		var latlng = new google.maps.LatLng( marker.position.lat(), marker.position.lng() );

		  		bounds.extend( latlng );

		  	});

		  	// only 1 marker?
		  	if( map.markers.length === 1 )
		  	{
		  		// set center of map
		  	    map.setCenter( bounds.getCenter() );
		  	    map.setZoom( 16 );
		  	}
		  	else
		  	{
		  		// fit to bounds
		  		map.fitBounds( bounds );
		  	}

		  }

		  /*
		  *  new_map
		  *
		  *  This function will render a Google Map onto the selected jQuery element
		  *
		  *  @type	function
		  *  @date	8/11/2013
		  *  @since	4.3.0
		  *
		  *  @param	$el (jQuery element)
		  *  @return	n/a
		  */

		  function new_map( $el ) {
			var width = jQuery(window).width();

            if (width <= 1024) {
              is_mobile = false;
            } else {
              is_mobile = true;
            }

		  	// var
		  	var $markers = $el.find('.marker');


		  	// vars
		  	var args = {
		  		zoom		: 16,
		  		center		: new google.maps.LatLng(0, 0),
		  		mapTypeId	: google.maps.MapTypeId.ROADMAP,
				draggable: is_mobile,
            	scrollwheel: false,
				// How you would like to style the map.
	            // This is where you would paste any style found on Snazzy Maps.
	            styles: [{
	              "featureType": "water",
	              "elementType": "geometry",
	              "stylers": [{
	                "color": "#e9e9e9"
	              }, {
	                "lightness": 17
	              }]
	            }, {
	              "featureType": "landscape",
	              "elementType": "geometry",
	              "stylers": [{
	                "color": "#f5f5f5"
	              }, {
	                "lightness": 20
	              }]
	            }, {
	              "featureType": "road.highway",
	              "elementType": "geometry.fill",
	              "stylers": [{
	                "color": "#ffffff"
	              }, {
	                "lightness": 17
	              }]
	            }, {
	              "featureType": "road.highway",
	              "elementType": "geometry.stroke",
	              "stylers": [{
	                "color": "#ffffff"
	              }, {
	                "lightness": 29
	              }, {
	                "weight": 0.2
	              }]
	            }, {
	              "featureType": "road.arterial",
	              "elementType": "geometry",
	              "stylers": [{
	                "color": "#ffffff"
	              }, {
	                "lightness": 18
	              }]
	            }, {
	              "featureType": "road.local",
	              "elementType": "geometry",
	              "stylers": [{
	                "color": "#ffffff"
	              }, {
	                "lightness": 16
	              }]
	            }, {
	              "featureType": "poi",
	              "elementType": "geometry",
	              "stylers": [{
	                "color": "#f5f5f5"
	              }, {
	                "lightness": 21
	              }]
	            }, {
	              "featureType": "poi.park",
	              "elementType": "geometry",
	              "stylers": [{
	                "color": "#dedede"
	              }, {
	                "lightness": 21
	              }]
	            }, {
	              "elementType": "labels.text.stroke",
	              "stylers": [{
	                "visibility": "on"
	              }, {
	                "color": "#ffffff"
	              }, {
	                "lightness": 16
	              }]
	            }, {
	              "elementType": "labels.text.fill",
	              "stylers": [{
	                "saturation": 36
	              }, {
	                "color": "#333333"
	              }, {
	                "lightness": 40
	              }]
	            }, {
	              "elementType": "labels.icon",
	              "stylers": [{
	                "visibility": "off"
	              }]
	            }, {
	              "featureType": "transit",
	              "elementType": "geometry",
	              "stylers": [{
	                "color": "#f2f2f2"
	              }, {
	                "lightness": 19
	              }]
	            }, {
	              "featureType": "administrative",
	              "elementType": "geometry.fill",
	              "stylers": [{
	                "color": "#fefefe"
	              }, {
	                "lightness": 20
	              }]
	            }, {
	              "featureType": "administrative",
	              "elementType": "geometry.stroke",
	              "stylers": [{
	                "color": "#fefefe"
	              }, {
	                "lightness": 17
	              }, {
	                "weight": 1.2
	              }]
	            }]
		  	};


		  	// create map
		  	var map = new google.maps.Map( $el[0], args);


		  	// add a markers reference
		  	map.markers = [];


		  	// add markers
		  	$markers.each(function(){

		      	add_marker( $(this), map );

		  	});


		  	// center map
		  	center_map( map );


		  	// return
		  	return map;

		  }

		  /*
		  *  document ready
		  *
		  *  This function will render each map when the document is ready (page has loaded)
		  *
		  *  @type	function
		  *  @date	8/11/2013
		  *  @since	5.0.0
		  *
		  *  @param	n/a
		  *  @return	n/a
		  */
		  // global var
		  var map = null;

		  $(document).ready(function(){

		  	$('.acf-map').each(function(){

		  		// create map
		  		map = new_map( $(this) );

		  	});

		  });

		  })(jQuery);



        //page preloader
        imagesLoaded( 'body', function() {
            $("#preloader").fadeOut("fast");
            $("body").removeClass("preload");
		// odpalenie foundation
        $(document).foundation();
        });


		// pokazywanie menu mobile
		$(".mobilemenu").hide();
		$(".mobilemenu-btn").click(function() {
		  $("body").toggleClass("preload");
		  $(".mobilemenu").slideToggle("normal");
		  return false;
		});

		// otwieranie reveal
		$(window).load(function() {
		  $(".open-popup").click(function() {
			$("body").addClass("preload");
		  });

		  $(document).on('closed.zf.reveal', '[data-reveal]', function() {
			$("body").removeClass("preload");
		  });
		});

		// reakcja na hamburgerze
		$('.mobilemenu-btn').click(function() {
		  $(this).toggleClass('open');
		});

		$('.answer').click(function() {
			$(".answer").removeClass("redborder");
			$(".answer input").prop( { checked: false} );
		  	$(this).addClass("redborder").find('input').prop( { checked: true} );
		});

		// menu rozwijane w menumobile
		$('.mobilemenu .offer li ul').hide();
		$('.mobilemenu .offer > li > a').click(function() {
		  $(this).next("ul").slideToggle();
		  return false;
		});

		// Replace all SVG images with inline SVG
		jQuery('img.svg').each(function(){
		 var $img = jQuery(this);
		 var imgID = $img.attr('id');
		 var imgClass = $img.attr('class');
		 var imgURL = $img.attr('src');
		 if (imgURL.length === 0) {
		 	return;
		 }
		 jQuery.get(imgURL, function(data) {
		     // Get the SVG tag, ignore the rest
		     var $svg = jQuery(data).find('svg');

		     // Add replaced image's ID to the new SVG
		     if(typeof imgID !== 'undefined') {
		         $svg = $svg.attr('id', imgID);
		     }
		     // Add replaced image's classes to the new SVG
		     if(typeof imgClass !== 'undefined') {
		         $svg = $svg.attr('class', $svg.attr('class') + ' ' + imgClass+' replaced-svg');
		     }

		     // Remove any invalid XML tags as per http://validator.w3.org
		     $svg = $svg.removeAttr('xmlns:a');

		     // Replace image with new SVG
		     $img.replaceWith($svg);

		 }, 'xml');
		});

		// fixed menu po scrollu
		var header_height = $("#header").height();
		var scroll = jQuery(document).scrollTop();
		if(scroll>header_height + 20){
		   jQuery('#topnav').addClass('fixed');
		} else {
		   jQuery('#topnav').removeClass('fixed');
		}

		jQuery( window ).scroll(function() {
		  var header_height = $("#header").height();
		  var scroll = jQuery(document).scrollTop();
		  if(scroll>header_height + 20){
	  		   jQuery('#topnav').addClass('fixed');
	  		} else {
	  		   jQuery('#topnav').removeClass('fixed');
	  		}
		});

		// fixed magellan
		var scroll2 = jQuery(document).scrollTop();
		if(scroll2>200){
		   jQuery('.magellan').addClass('fixed');
		} else {
		   jQuery('.magellan').removeClass('fixed');
		}

		jQuery( window ).scroll(function() {
			var scroll2 = jQuery(document).scrollTop();
			if(scroll2>200){
			   jQuery('.magellan').addClass('fixed');
			} else {
			   jQuery('.magellan').removeClass('fixed');
			}
		});

		// wywoływanie fontów
        WebFontConfig = {
          google: { families: [ 'Open+Sans:400,300,600,700,800:latin,latin-ext', 'Raleway:400,300,500,600,700,800:latin,latin-ext' ] }
        };
        (function() {
          var wf = document.createElement('script');
          wf.src = 'https://ajax.googleapis.com/ajax/libs/webfont/1/webfont.js';
          wf.type = 'text/javascript';
          wf.async = 'true';
          var s = document.getElementsByTagName('script')[0];
          s.parentNode.insertBefore(wf, s);
        })();

		// wow js http://mynameismatthieu.com/WOW/
		wow = new WOW({
        	mobile: false
		});
        wow.init();

		// nice select http://hernansartorio.com/jquery-nice-select/
	  	$('.nice-select').niceSelect();

		// flickity js http://flickity.metafizzy.co/
		$('.header-slider').flickity({
			cellAlign: 'left',
			contain: true,
			wrapAround: true,
			prevNextButtons: false,
			pageDots: true,
			autoPlay: true,
			cellSelector: '.gallery-cell'
		});
		// slider z fotkami
		$('.photos-slider').flickity({
			cellAlign: 'center',
			contain: true,
			wrapAround: true,
			prevNextButtons: false,
			pageDots: true,
			autoPlay: 8*1000
		});

		// slider z fotkami
		$('section:not(.colors):not(.panels) .product-slider').flickity({
			cellAlign: 'center',
			contain: true,
			wrapAround: true,
			prevNextButtons: false,
			pageDots: true,
			autoPlay: true
		});

		// magnific popup
		$('section').each(function () {
			$(this).magnificPopup({
				delegate: '.image-popup',
				type: 'image',
				titleSrc: 'title',
				gallery: { enabled: true }
			});	
		});
		


		var mouseEnterPosterPoint = function ($poster) {
			return function (event) {
				$poster.find('.instruction').hide();
				$poster
					.find('dt.active')
					.removeClass('active')
					.nextUntil('dt')
					.removeClass('active')
				;
				$poster
					.find('dt')
					.eq($(event.target).data('index'))
					.addClass('active')
					.nextUntil('dt')
					.addClass('active')
				;
			};
		};
		var mouseLeavePosterPoint = function ($poster) {
			/*return function (event) {
				$poster
					.find('dt')
					.eq($(event.target).data('index'))
					.removeClass('active')
					.nextUntil('dt')
					.removeClass('active')
				;
			};*/
		};
		//var $ = jQuery;
	      $('.interactive-poster').each(function (index, poster) {
	        var $poster = $(poster);
	        $poster.find('dl dt').each(function (index, dt) {
	          var $dt = $(dt);
	          var positions = ($dt.data('positions'));
	          for (var i = 0; i < positions.length; i++) {
	            var $point =$('<div class="point"></div>')
	              .css('left', positions[i][0])
	              .css('top', positions[i][1])
	              .data('index', index)
	              .appendTo($poster)
	            ;
	            $point.mouseenter(mouseEnterPosterPoint($poster));
	            $point.mouseleave(mouseLeavePosterPoint($poster));
	          }
	        });
	      });


		function resizeColorImages(factor) { 
			return function (event) {

				event.preventDefault();
				event.stopPropagation();

				var maxColumns = 10;
				var $event = $(event);
				var $target = $(event.target);
				var elements = [];
				var newSize;
				$target.parents('.row').eq(1).next('.product-slider').find('.columns').each(function (index, element) {
					var $element = $(element);

					var classesAttribute = $element.attr('class');
					var result = classesAttribute.match(/large-([0-9]{1,2})/);
					var largeSize = result[1];
					newSize = parseInt(largeSize) + factor;

					if (newSize < 1 || newSize > 4) {
						return;
					}
					$element.removeClass('large-' + largeSize);
					$element.addClass('large-' + newSize);

					$('.zoomin').removeClass('disabled');
					$('.zoomout').removeClass('disabled');
					if (newSize === 1) {
						$('.zoomout').addClass('disabled');
					}
					if (newSize === 4) {
						$('.zoomin').addClass('disabled');
					}

					elements.push($element.detach());
				});
				if (elements.length === 0) {
					return;
				}
				$target.parents('.row').eq(1).next('.product-slider').find('.row').remove();
				var maxInRow = Math.floor(maxColumns / newSize);
				var rowFactory = function () {
					var $row = $('<div/>').addClass('row').addClass('collapse');
					$row.appendTo($target.parents('.row').eq(1).next('.product-slider'));
					return $row;
				};
				var $row = rowFactory();
				for (var i = 0; i < elements.length; i++) {
					if (i % maxInRow === 0) {
						$row = rowFactory();
					}
					elements[i].appendTo($row);
				}
			};
		}
		$('.zoomin').click(resizeColorImages(1));
		$('.zoomout').click(resizeColorImages(-1));

		jQuery('.sidebar .podobne .box-offer').css('width', 360);
		jQuery('.sidebar .podobne').flickity({
			cellAlign: 'left',
			contain: true,
			wrapAround: true,
			prevNextButtons: false,
			pageDots: true,
			autoPlay: 8*1000,
			cellSelector: '.box-offer'
		});


		var mouseEnterDoorPanel = function () {
			return function (event) {
				//event.stopPropagation();
				var $target = $(event.target);
				var $parent = $target.parent();
				$parent.css({
					'position': 'relative',
					'display': 'block'
				});
				$target.parents('.product-slider').eq(0).find('.zoom').hide();
				var $zoom = $target.parents('.columns').eq(0).find('.zoom');
				if ($zoom.size() > 0) {
					$zoom.css('display', 'block');
					return;
				}
				
				$zoom = $('<div/>').addClass('zoom');
				$img = $('<img/>');
				$img
					.attr('src', $parent.attr('href'))
					.appendTo($zoom)
				;
				$zoom.appendTo($parent);
				$img.load(function () {
					var naturalWidth = $img[0].naturalWidth;
					var naturalHeight = $img[0].naturalHeight;
					if (naturalHeight > 410) {
						var ratio = naturalWidth/naturalHeight;
						naturalHeight = 410;
						naturalWidth = ratio * naturalHeight;
					}
					$zoom.css('width', naturalWidth);
					$zoom.css('height', naturalHeight);
					$zoom.css('left', ($target.parents('.columns')[0].clientWidth)/2 - naturalWidth/2);
					$zoom.css('top', ($target[0].clientHeight)/2 - naturalHeight/2);
				});
				$zoom.mouseleave(function (event) {
					var $target = $(event.target);
					$target.parents('a')
						.css('position', 'static')
						.css('display', 'inline')
					;
					$target.parents('a').find('.zoom').css('display', 'none');
				});
			};
		};
		$(function () {
			$('.panels .gallery-cell img').each(function (index, element) {
				var $element = $(element);
				$element.mouseenter(mouseEnterDoorPanel());
			});
		});


jQuery(function () {
  var $ = jQuery;
    var view360Elements = jQuery('.view360');
    for (var i = 0; i < view360Elements.length; i++) {
      var $view360Element = jQuery(view360Elements[i]);
      var view360 = $view360Element.ThreeSixty({
        totalFrames: images360[i].length,
        endFrame: images360[i].length,
        currentFrame: 1,
        imgList: '.threesixty_images',
        progress: '.spinner',
        imgArray: images360[i],
        height: 310,
        width: 246
      });
      //$view360Element.append($('<div/>').addClass('button blue nobg').text('x1'));     
    }
    jQuery('.views360 .buttons a.button').each(function (index, element) {
      var $element = jQuery(element);
      $element.click(function (event) {
        event.stopPropagation();
        event.preventDefault();
        var index = parseInt($element.data('view360-index'));

        jQuery('.view360').css('display', 'none');
        jQuery('.view360').eq(index).css('display', 'block');
      });
    });

});

      },
      finalize: function() {
        // JavaScript to be fired on all pages, after page specific JS is fired
      }
    },
    // Home page
    'home': {
      init: function() {
        // JavaScript to be fired on the home page

      },
      finalize: function() {
        // JavaScript to be fired on the home page, after the init JS
      }
    },

    'kontakt': {
		init: function() {
	      // JavaScript to be fired on the home page

	    },
	    finalize: function() {
	      // JavaScript to be fired on the home page, after the init JS
	    }
    }
  };

  // The routing fires all common scripts, followed by the page specific scripts.
  // Add additional events for more control over timing e.g. a finalize event
  var UTIL = {
    fire: function(func, funcname, args) {
      var fire;
      var namespace = Sage;
      funcname = (funcname === undefined) ? 'init' : funcname;
      fire = func !== '';
      fire = fire && namespace[func];
      fire = fire && typeof namespace[func][funcname] === 'function';

      if (fire) {
        namespace[func][funcname](args);
      }
    },
    loadEvents: function() {
      // Fire common init JS
      UTIL.fire('common');

      // Fire page-specific init JS, and then finalize JS
      $.each(document.body.className.replace(/-/g, '_').split(/\s+/), function(i, classnm) {
        UTIL.fire(classnm);
        UTIL.fire(classnm, 'finalize');
      });

      // Fire common finalize JS
      UTIL.fire('common', 'finalize');
    }
  };

  // Load Events
  $(document).ready(UTIL.loadEvents);

})(jQuery); // Fully reference jQuery after this point.
